import React, { useState } from 'react';
import "./qualification.css"
import { Reveal } from '../utils/Reveal';

const Qualification = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <section className="qualification section">
            <Reveal>
                <h2 className="section__title">Qualification</h2>
                <span className="section__subtitle"></span>
            </Reveal>
            <div className="qualification__container container">
                {/* Tabs for Education and Experience */}
                <Reveal>
                    <div className="qualification__tabs">
                        <div className={toggleState === 1 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"} onClick={() => toggleTab(1)}>
                            <i className="uil uil-graduation-cap qualification__icon"></i> Education
                        </div>

                        <div className={toggleState === 2 ? "qualification__button qualification__active button--flex" : "qualification__button button--flex"} onClick={() => toggleTab(2)}>
                            <i className="uil uil-briefcase-alt qualification__icon"></i> Experience
                        </div>
                    </div>
                </Reveal>
                {/* Qualification content */}
                <div className="qualification__sections">
                    <Reveal>
                        {/* Education content */}
                        <div className={toggleState === 1 ? "qualification__content qualification__content-active" : "qualification__content"}>
                            <div className="qualification__data">
                                {/* First education entry */}
                                <div>
                                    <h3 className="qualification__title">Cycle d’orientation de la Glâne</h3>
                                    <span className="qualification__subtitle">CO - Secondary school - Romont, Switzerland</span>
                                    <div className="qualification__calender">
                                        <i className="uil uil-calendar-alt"></i> 2017 - 2020
                                    </div>
                                </div>
                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>
                            </div>
                            {/* Second education entry */}
                            <div className="qualification__data">
                                <div></div>

                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>

                                <div>
                                    <h3 className="qualification__title">Swiss Federal Certificate of Competence (CFC), Computer Science</h3>
                                    <span className="qualification__subtitle">EMF - Ecole des métiers / Vocational School - Fribourg, Switzerland</span>
                                    <div className="qualification__calender">
                                        <i className="uil uil-calendar-alt"></i> 2020 - Present
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Experience content */}
                        <div className={toggleState === 2 ? "qualification__content qualification__content-active" : "qualification__content"}>
                            <div className="qualification__data">
                                {/* First experience entry */}
                                <div>
                                    <h3 className="qualification__title">Multi-skilled Customer Service and Stock</h3>
                                    <span className="qualification__subtitle">Coop Pronto - Matran, Switzerland</span>
                                    <div className="qualification__calender">
                                        <i className="uil uil-calendar-alt"></i> June 2019 – August 2019
                                    </div>
                                </div>
                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>
                            </div>

                            {/* Second experience entry */}
                            <div className="qualification__data">
                                <div></div>

                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>

                                <div>
                                    <h3 className="qualification__title">Facility Management</h3>
                                    <span className="qualification__subtitle">AEL SA Facility Management - Granges-Paccot, Switzerland</span>
                                    <div className="qualification__calender">
                                        <i className="uil uil-calendar-alt"></i> July 2023 - August 2023
                                    </div>

                                </div>
                            </div>

                            <div className="qualification__data">
                                {/* Third experience entry */}
                                <div>
                                    <h3 className="qualification__title">Facility Management</h3>
                                    <span className="qualification__subtitle">AEL SA Facility Management - Granges-Paccot, Switzerland</span>
                                    <div className="qualification__calender">
                                        <i className="uil uil-calendar-alt"></i> July 2024 - August 2024
                                    </div>
                                </div>
                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>
                            </div>
                            {/* First experience entry */}
                            <div className="qualification__data">
                                <div></div>

                                <div>
                                    <span className="qualification__rounder"></span>
                                    <span className="qualification__line"></span>
                                </div>

                                <div>
                                    <h3 className="qualification__title">Full-stack Web Developer</h3>
                                    <span className="qualification__subtitle">Armour by Granite · Internship - Galway, County Galway, Ireland</span>
                                    <div className="qualification__calender">
                                        <i className="uil uil-calendar-alt"></i> August 2024 – December 2024
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Reveal>
                </div>
            </div>
        </section >
    )
}

export default Qualification 